export default {
    handleErrosUnidade(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.name = error.descricao ? error.descricao[0] : null;
        errorForm.codigo_smd = error.codigo_smd ? error.codigo_smd[0] : null;
        errorForm.cnpj = error.cnpj ? error.cnpj[0] : null;
        errorForm.city = error.id_cidade ? error.id_cidade[0] : null;
        errorForm.region = error.id_regiao ? error.id_regiao[0] : null;
        errorForm.erroResponsible = error.id_usuario_responsavel ? error.id_usuario_responsavel[0] : null;
    }
}