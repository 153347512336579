<template>
     <b-row>
        <b-col lg="6" md="6" sm="12" class="pr-0">
            <b-form-group :label="labelName" label-for="responsibleUser"
                :state="error ? false:null"
            >
                <v-select
                    id="uf"
                    :reduce="(option => option.id_usuario)"
                    v-model="responsibleSelected"
                    :options="responsibleUsers"
                    @input="selectResponsible"
                    :disabled="selectDisabled"
                    label="nome"
                    placeholder="Selecione um responsável"
                >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                <small v-if="error" class="text-danger">
                    {{ error }}
                </small>
            </b-form-group>
        </b-col>
        <b-col lg="2" md="6" sm="12" class="pr-0">
        </b-col>
        <b-col lg="4" md="6" sm="12">
            <b-form-group label="E-mail" label-for="email-blocked-input">
                <b-form-input
                    id="email-blocked-input"
                    v-model="email"
                    disabled
                />
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';

import vSelect from 'vue-select';
import {getUserData} from "@/auth/utils";

export default {
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput,
        vSelect
    },

    props: {
        labelName: {
            type: String,
            required: true
        },
        selectDisabled: Boolean,
        preSelected: Number,
        error: String,
        idDepartamento: {
            type: Number|null,
            required: true
        },
    },

    data() {
        return {
            userData: getUserData(),
            responsibleUsers: [],
            responsibleSelected: null,
            email: "",
            firstLoad: true,
        }
    },

    methods: {
        async loadResponsibles() {
            await this.$http.get(
                this.$api.getUsuariosDoDepartamento(this.idDepartamento)
            ).then(({data}) => {
                this.responsibleUsers = data;
            });
        },

        selectResponsible(event) {
            this.changeEmail(event);
            this.$emit('selected', event);
        },

        async changeEmail(event) {
            if(event) {
                const responsible = this.responsibleUsers.find(user => {
                    return user.id_usuario == event
                });
                this.email = responsible.email;
                return;
            }

            this.email = null;
        }
    },

     watch: {
        preSelected: {
            async handler(value) {
                if(this.firstLoad){
                    await this.loadResponsibles();
                    this.firstLoad = false;
                }
                this.responsibleSelected = value;
                this.changeEmail(value);
            }
        },

        idDepartamento: {
            async handler(idDepartamento) {
                if(idDepartamento) { await this.loadResponsibles(); }
            }
        }
     }
}
</script>


